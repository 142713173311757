import React, { useState } from "react";

const IndustryWidget = (props) =>
{
  const [selectedOption, setSelectedOption] = useState(null);
  let messages = props.state.messages;
  const jobIndustryMessage = messages.find(
    (message) =>
      message.message === "Please select the job industry you are interested in."
  );
  const jobIndustry = jobIndustryMessage.industry;

  const handleOptionClick = async (selectedOption,selectedId) =>
  {
    setSelectedOption(selectedId);
    props.actions.handleData(selectedOption,selectedId);
  };

  return (
    <div className="options-container">
      {jobIndustry?.map((option, index) =>
      {
        // console.log("selectedOption", selectedOption);
        // console.log("selectedOption option._id", option._id);
        const buttonClass =
          selectedOption === option._id ? "option-button selected" : "option-button";

        return (
          <button
            key={index}
            className={buttonClass}
            onClick={() => handleOptionClick(option.industry,option._id)} // Pass selected option ID to handleOptionClick
          >
            {option.industry}
          </button>
        );
      })}
    </div>
  );
};

export default IndustryWidget;
