import React, { useState, useEffect } from "react";
import { ApiHelper } from '../config/ApiHelper';
import { API } from '../config/Api';
import '../assets/css/chatbot.css';

const Options = (props) => {
  const [selectedOptions, setSelectedOptions] = useState({});
  const [value, setValue] = useState('');
  const [labels, setLabels] = useState({});

  const quesLabel = props.payload.labelData.questionLable;
  const { showNextQuestion } = props;
  const lastMessage = props.state.messages[props.state.messages.length - 1];

  useEffect(() => {
    // Initialize the selected option for the current question
    if (props.state.messages.length > 0) {
      const prevSelected = selectedOptions[quesLabel];
      setValue(prevSelected || '');
    }
  }, [props.state.messages, quesLabel, selectedOptions]);

  const handleOptionClick = async (selectedOption) => {
    // Check if the option is already selected
    if (value === selectedOption) {
      return;
    }

    setValue(selectedOption);
    setSelectedOptions(prev => ({
      ...prev,
      [quesLabel]: selectedOption,
    }));

    const obj = { "id": lastMessage.userId, "label": lastMessage.questionLabel, "responseValue": selectedOption };
    if (obj.id === undefined || obj.label === undefined || obj.responseValue === undefined) {
      console.error('Missing required data:', obj);
      alert('Some required data is missing. Please check your url.');
      // setIsFetched(false); // Reset flag on validation failure
      return; // Exit the function to prevent API call
    }
    const apiEndpoint = lastMessage.questionLabel.length === 1 ? API.submitAnswers : API.combinationScore;

    await ApiHelper.post(apiEndpoint, obj)
      .then((resData) => {
        console.log("resData", resData);
        props.actions.showNextQuestion();
      })
      .catch((err) => {
        console.log(err, 'err');
      });
  };

  return (
    <div>
      <div className="radio_slider-custom">
        <div className="rating_form">
          <div className="topLabels">
            <span className="aligLab">{props.payload.labelData.firstLabel}</span>
            <span className="aligLab">{props.payload.labelData.middleLabel}</span>
            <span className="aligLab">{props.payload.labelData.lastLabel}</span>
          </div>
          <div className="debt-amount-slider">
            {[1, 2, 3, 4, 5, 6, 7].map((option) => (
              <React.Fragment key={option}>
                <input
                  type="radio"
                  name={`debt-amount-${quesLabel}-${option}`}
                  id={`option-${quesLabel}-${option}`}
                  checked={value === option}
                  onChange={() => handleOptionClick(option)}
                  disabled={selectedOptions[quesLabel] !== undefined}
                />
                <label
                  htmlFor={`option-${quesLabel}-${option}`}
                  data-debt-amount={option}
                  style={{ cursor: selectedOptions[quesLabel] === undefined ? 'pointer' : 'not-allowed' }}
                ></label>
              </React.Fragment>
            ))}
            <div className="debt-amount-pos"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Options;
