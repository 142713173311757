import React, { useState, useEffect } from 'react';
import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import config from './config/chatbotConfig';
import MessageParser from './chatbots/MessageParser';
import ActionProvider from './chatbots/ActionProvider';
import './assets/css/chatbot.css';
import { ApiHelper } from './config/ApiHelper';
import { API } from './config/Api';
import logo from "./assets/img/purple.svg"

function App() {
  const [showOptions, setShowOptions] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  // const [companies,setCompanies] = useState([]);

  // useEffect(()=>{
  //   fetchAllCompanies()
  // },[])

  // const fetchAllCompanies = async () => {
  //   const response = await ApiHelper.get(API.getCompanies);
  //   setCompanies(response.data.data);
  // }

  const handleOptionSelect = (option) => {
    localStorage.setItem('company', option);
    setSelectedOption(option);
    setShowOptions(false);
  };

  const renderOptions = () => {
    return (
      <div className="options-container">
        {/* <h2>Please select a company:</h2>
        {companies.map((company, index) => (
          <div key={index}>
            <button className="option-button" onClick={() => handleOptionSelect(company.companyname)}>{company.companyname}</button>
          </div>
        ))} */}
      </div>
    );
  };

  return (
    <div className="App" style={{ paddingLeft: '4px', paddingTop: '100px' }}>
      {/* {showOptions && renderOptions()} */}
      {/* {!showOptions && ( */}
        <Chatbot
          config={config}
          messageParser={MessageParser}
          actionProvider={ActionProvider}
        />
        <div className="header-icon-img-box">
        <img src={logo} alt="test" className="d-inline bot-icon left-bot-icon logoStyle" /></div>
      <div className='footer-section'>
      {/*<div class="user-action">
      <button className='menu-btn'>Begin Application</button>
      </div>*/}
      <div class="user-text">
      <button className='menu-text'><a href='' className='fot-link'>Terms </a>and <a href=''  className='fot-link'>Privacy Policy</a> </button>
      </div>
      </div>
      {/* )} */}
    </div>
  );
}

export default App;
