// widgets/StartButton.js
import React from 'react';
import '../assets/css/style.css'

const StartButton = ({ actions }) => {
  const handleStart = () => {
    console.log('starting--')
    actions.fetchIndustry();
  };

  return (
    <div className='start-button-container'>
      <button className='startbtn' onClick={()=>{handleStart()}}>Start</button>
    </div>
  );
};

export default StartButton;
