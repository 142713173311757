// api link helper class

const BASEURL = 'https://sophi.avenu.ai/api/';
export const API = {
    addUsers: BASEURL + 'addUsers',
    getUserCode: BASEURL + 'getUserFinalCode?id=',
    getDistinctTraits: BASEURL + 'getDistinctTraits?id=',
    getAllCandidates: BASEURL + 'getAllUsers',
    updateCandidate: BASEURL + 'updateCandidateDataById',
    getSummary: BASEURL + 'getSummary?id=',
    login: BASEURL + 'login',
    getIndustry: BASEURL + 'getIndustries',
    getCategory: BASEURL + 'getSubCategory?id=',
    addPosition: BASEURL + 'addJobPosition',
    getJobPosition: BASEURL + 'getJobPosition',
    addAdmin: BASEURL + 'addAdmin',
    listUsers: BASEURL + 'listUsers',
    deleteUser: BASEURL + 'deleteUser?id=',
    getCompanies:BASEURL + 'getCompanies',
    updateRole:BASEURL + 'updateRole',
    submitAnswers : BASEURL + 'submitAnswers',
    getSubQuestions : BASEURL + 'getSubQuestions?id=',
    combinationScore: BASEURL + 'combinationScore',
    getCompany:BASEURL + 'getCompany?companyId='
}
