import { createChatBotMessage } from 'react-chatbot-kit';
import Options from '../widgets/Options';
import IndustryWidget from '../widgets/IndustryWidget';
import CategoryWidget from '../widgets/CategoryWidget';
import StartButton from '../widgets/StartButton';
// import CustomMessage from '../widgets/custommessage';


const config = {
  initialMessages: [createChatBotMessage("Hi there, and welcome to SophiAI! This assessment consists of 12 questions and should only take you about one minute to complete. It’s designed to learn more about you and how you work!", {
    widget: 'startButton', // Use the startButton widget
  })],
  botName: 'SophiAI',
  customComponents: {},
  customMessages: {
    custom: (props) => <Options {...props} />,
  },
  widgets: [
    {
      widgetName: "userOptions",
      widgetFunc: (props) => <Options {...props} />,
    },
    {
      widgetName: "industryWidget",
      widgetFunc: (props) => <IndustryWidget {...props} />,
    },
    {
      widgetName: "categoryWidget",
      widgetFunc: (props) => <CategoryWidget {...props} />,
    },
    {
      widgetName: "startButton", // Add the startButton widget
      widgetFunc: (props) => <StartButton {...props} />,
    },
  ],
};

export default config;