import React, { useState, useEffect } from 'react';
import { ApiHelper } from '../config/ApiHelper';
import { API } from '../config/Api';
import Options from '../widgets/Options';

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const [userData, setUserData] = useState({
    responses: [],
    currentQuestion: 1,
  });
  const [questions, setQuestions] = useState([]);
  const [subQuestions, setSubQuestions] = useState([]);
  const [userId, setUserId] = useState('');
  const [fetchedUser, setFetchedUser] = useState();
  const [totalCompatibilityScore, setTotalCompatibilityScore] = useState([]);
  const [isFetched, setIsFetched] = useState(false); // New flag
  const [companyId, setCompanyId] = useState();
  const [companyJobId, setCompanyJobId] = useState();
  const [selectedOptions, setSelectedOptions] = useState({}); // Track selected options

  useEffect(() => {
    if (!isFetched) {
      // fetchIndustry();
    }
  }, [isFetched]);

  const fetchIndustry = async () => {
    console.log('inside fetchz');
    const company = localStorage.getItem('company');
    let url = window.location.href;
    let queryParams = getQueryParams(url);
    let id = queryParams['id'];
    let job_id = queryParams['job_id'];
    let ind_id = queryParams['ind_id'];
    let sub_id = queryParams['sub_id'];
    let com_id = queryParams['com_id'];
    setCompanyJobId(job_id);
    setCompanyId(id);
    let companyResponse;
    if (com_id) {
      companyResponse = await ApiHelper.get(API.getCompany + com_id);
    }

    try {
      // Define the object with initial values
      const obj = {
        userId: id,
        industryId: ind_id,
        subcategoryId: sub_id,
        company: com_id ? companyResponse.data.data.companyname : 'avenu',
      };
    
      // Log the object for debugging purposes
      console.log('obj', obj);
    
      // Check if any required values are missing or undefined
      if (obj.userId === undefined || obj.industryId === undefined || obj.subcategoryId === undefined) {
        console.error('Missing required data:', obj);
        alert('Some required data is missing. Please check your url.');
        setIsFetched(false); // Reset flag on validation failure
        return; // Exit the function to prevent API call
      }
    
      // If all required values are present, proceed with the API call
      const response = await ApiHelper.post(API.addUsers, obj);
    
      // Handle the response from the API
      if (response.data.message === "This assessment is completed") {
        alert('This assessment is completed');
      } else {
        setQuestions(response.data.questions);
        setUserId(response.data.user._id);
        setIsFetched(true); // Set the flag to true only after fetching data
      }
    } catch (err) {
      // Handle any errors that occur during the API call
      console.log('Error fetching industry data:', err);
      setIsFetched(false); // Reset flag on error
    }
  };

  function getQueryParams(url) {
    let params = {};
    let parser = document.createElement('a');
    parser.href = url;
    let query = parser.search.substring(1);
    let vars = query.split('&');
    vars.forEach(function (v) {
      let pair = v.split('=');
      if (pair[0] && pair[1]) {
        params[pair[0].replace('?', '')] = pair[1];
      }
    });
    return params;
  }

  const handleNextQuestion = async (message) => {
    // your existing code
  };

  useEffect(() => {
    if (subQuestions[0]) {
      const labelData = {
        questionLable: subQuestions[0].label,
        firstLabel: 'Strongly Disagree',
        middleLabel: '',
        lastLabel: 'Strongly Agree'
      };
      const firstQuestionMessage = createChatBotMessage(subQuestions[0].question, { widget: 'userOptions', payload: { "labelData": labelData } });
      firstQuestionMessage.questionLabel = subQuestions[0].label;
      firstQuestionMessage.userId = userId;
      setState((prevState) => ({
        ...prevState,
        messages: [...prevState.messages, firstQuestionMessage],
      }));
    } else if (userId) {
      fetchUserCode();
    }
  }, [subQuestions]);

  const handleData = async (selectedOption, id) => {
    const currentIndex = userData.currentQuestion - 1;
    const updatedResponses = [...userData.responses];
    updatedResponses[currentIndex] = selectedOption;

    const industryId = id;
    const obj = { category: selectedOption };

    // Update selected options to prevent re-selection
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [userData.currentQuestion]: selectedOption,
    }));

    try {
      const resData = await ApiHelper.get(API.getCategory + industryId);
      const data = resData.data.data.category;
      const nextQuestionMessage = createChatBotMessage('Please select the job position you are seeking', { widget: 'categoryWidget' });
      nextQuestionMessage.category = data;
      setState((prevState) => ({
        ...prevState,
        messages: [...prevState.messages, nextQuestionMessage],
      }));
    } catch (err) {
      console.log('Error fetching category data:', err);
    }
  };

  const handleCategoryData = async (selectedOption) => {
    handleNextQuestion(selectedOption);
  };

  const fetchUserCode = async () => {
    try {
      console.log('inside function');
      console.log('userId', userId);
      console.log('companyId', companyId);
      const response = await ApiHelper.get(API.getUserCode + userId);
      if (response.data.user.totalCompatibilityScore) {
        try {
          console.log('inside try');
          setTimeout(() => {
            window.location.href = 'https://app.avenu.ai/career';
          }, 4000); // 4000 milliseconds = 4 seconds
        } catch (err) {
          console.log('scoring error', err);
        }
        const finishedMessage = createChatBotMessage('Thank you for your cooperation. We will email you the results soon. Have a great day!');
        setState((prevState) => ({
          ...prevState,
          messages: [...prevState.messages, finishedMessage],
        }));
      }
    } catch (error) {
      console.log('Error fetching user code:', error);
    }
  };

  const getSubQuestions = async () => {
    try {
      const response = await ApiHelper.get(API.getSubQuestions + userId);
      setSubQuestions(response?.data?.combinationQuestions);
    } catch (err) {
      console.log('Error fetching sub questions:', err);
    }
  };

  useEffect(() => {
    if (questions.length > 0) {
      if (questions[0]) {
        let labelData;
        switch (questions[0].label) {
          case 'A':
            labelData = {
              questionLable: 'A',
              firstLabel: 'Current issues',
              middleLabel: 'Both / Neither',
              lastLabel: 'Future possibilities'
            };
            break;
          case 'B':
            labelData = {
              questionLable: 'B',
              firstLabel: 'Analyzing Causes',
              middleLabel: 'Both / Neither',
              lastLabel: 'Generating ideas'
            };
            break;
          case 'C':
            labelData = {
              questionLable: 'C',
              firstLabel: 'Expected difficulties',
              middleLabel: 'Both / Neither',
              lastLabel: 'The results to be obtained'
            };
            break;
          case 'D':
            labelData = {
              questionLable: 'D',
              firstLabel: 'Calm and Reserved',
              middleLabel: 'Both / Neither',
              lastLabel: 'Cheerful and Fun'
            };
            break;
          case 'E':
            labelData = {
              questionLable: 'E',
              firstLabel: 'Efficiency of task progression',
              middleLabel: 'Both / Neither',
              lastLabel: 'Completeness of the task result'
            };
            break;
          case 'F':
            labelData = {
              questionLable: 'F',
              firstLabel: 'Strongly Disagree',
              middleLabel: 'Both / Neither',
              lastLabel: 'Strongly Agree'
            };
            break;
          case 'G':
            labelData = {
              questionLable: 'G',
              firstLabel: 'Strongly Disagree',
              middleLabel: 'Both / Neither',
              lastLabel: 'Strongly Agree'
            };
            break;
          default:
            break;
        }
        const firstQuestionMessage = createChatBotMessage(questions[0].question, { widget: 'userOptions', payload: { "labelData": labelData, "selectedOptions": selectedOptions } });
        firstQuestionMessage.questionLabel = questions[0].label;
        firstQuestionMessage.userId = userId;
        firstQuestionMessage.labelData = labelData;

        setState((prevState) => ({
          ...prevState,
          messages: [...prevState.messages, firstQuestionMessage],
        }));
      }
    }
  }, [questions, setState]);

  const showNextQuestion = () => {
    if (questions.length > 0) {
      if (questions.length === 1) {
        getSubQuestions(); // Fetch sub questions when questions array is empty
      }
      setQuestions((prevQuestions) => prevQuestions.slice(1));
    } else {
      if (subQuestions.length > 0) {
        setSubQuestions((prevQuestions) => prevQuestions.slice(1));
      }
    }
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleNextQuestion,
            showNextQuestion,
            handleData,
            handleCategoryData,
            fetchIndustry,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
