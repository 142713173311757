import React,{useState} from "react";

const CategoryWidget = (props) =>
{
  const [selectedOption, setSelectedOption] = useState(null);
  let messages = props.state.messages;
  const jobCategoryMessage = messages.find(message => message.message === 'Please select the job position you are seeking');
  const jobCategory = jobCategoryMessage.category;

  const handleOptionClick = async (selectedOption,selectedId) =>
  {
    setSelectedOption(selectedId)
    props.actions.handleCategoryData(selectedOption,selectedId);
  };

  return (
    <div className="options-container">
      {jobCategory?.map((option, index) =>
      {
        const buttonClass =
          selectedOption === option._id ? "option-button selected" : "option-button";
        return (
          <button
            key={index}
            className={buttonClass}
            onClick={() => handleOptionClick(option.name,option._id)} // Pass selected option to handleOptionClick
          >
            {option.name}
          </button>
        );
      })}
    </div>
  );
};

export default CategoryWidget;